import React, { useEffect } from 'react';
import { Box, Typography, Stack, Button, useTheme, alpha, IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import { useLanguage } from '../../contexts/LanguageContext';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Cookies from 'js-cookie';

const RouteSelector = ({ 
  routes, 
  selectedRoute, 
  onRouteSelect,
  routeNames 
}) => {
  const theme = useTheme();
  const { t } = useLanguage();
  
  // Get favorite routes from cookie
  const [favoriteRoutes, setFavoriteRoutes] = React.useState(() => {
    const cookieValue = Cookies.get('favoriteRoutes');
    if (cookieValue) {
      try {
        return JSON.parse(cookieValue);
      } catch (error) {
        console.error('Error parsing favoriteRoutes cookie:', error);
        return [];
      }
    }
    return [];
  });
  
  // State for snackbar
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  
  // Migrate from old single favorite route to new format
  useEffect(() => {
    const legacyFavoriteRoute = Cookies.get('favoriteRoute');
    if (legacyFavoriteRoute && !Cookies.get('favoriteRoutes')) {
      // Convert old format to new format
      const newFavoriteRoutes = [legacyFavoriteRoute];
      Cookies.set('favoriteRoutes', JSON.stringify(newFavoriteRoutes), { 
        expires: 365,
        path: '/',
        sameSite: 'Lax'
      });
      setFavoriteRoutes(newFavoriteRoutes);
      
      // Remove old cookie
      Cookies.remove('favoriteRoute', { path: '/' });
    }
  }, []);
  
  if (!routes?.length) return null;

  // Define route priority (蓝光 routes first, then 备用 routes)
  const routePriority = {
    'NBY': 1,      // 无广N (247看专线)
    'heimuer': 2,  // 蓝光H
    'tym3u8': 3,   // 蓝光T
    'ffm3u8': 4,   // 蓝光F
    'ikm3u8': 5,   // 蓝光I
    '1080zyk': 6,  // 蓝光Y
    'bfzym3u8': 7, // 备用B
    'okm3u8': 8,   // 备用O
    'FanTv': 9,    // 无广F
    'XCGZ': 10,    // 无广G
    'qq': 11,      // 无广Q
    'sh': 12,      // 无广S
    'youku': 13,   // 无广Y
    'Wasu': 14,    // 无广W
    'qiyi': 15,    // 无广I
    'rrsp': 16,    // 无广R
    'bilibili': 17,// 无广B
    'mgtv': 18,    // 无广M
  };

  // Sort routes and keep track of original indices
  const sortedRoutesWithIndices = routes.map((route, index) => ({
    route,
    originalIndex: index,
    priority: routePriority[route] || 999
  })).sort((a, b) => a.priority - b.priority);
  
  // Handle toggling a route as favorite
  const handleToggleFavorite = (route) => {
    let newFavoriteRoutes;
    
    if (favoriteRoutes.includes(route)) {
      // Remove from favorites
      newFavoriteRoutes = favoriteRoutes.filter(r => r !== route);
      setSnackbarMessage(t('common', 'favoriteRouteRemoved'));
    } else {
      // Add to favorites
      newFavoriteRoutes = [...favoriteRoutes, route];
      setSnackbarMessage(t('common', 'favoriteRouteSet'));
    }
    
    setFavoriteRoutes(newFavoriteRoutes);
    
    // Save to cookie
    Cookies.set('favoriteRoutes', JSON.stringify(newFavoriteRoutes), { 
      expires: 365, // Store for 1 year
      path: '/',
      sameSite: 'Lax'
    });
    
    setSnackbarOpen(true);
  };
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  
  return (
    <Box sx={{ mb: 3 }}>
      <Typography 
        variant="subtitle1" 
        gutterBottom 
        sx={{ 
          color: 'text.primary',
          fontWeight: 600,
          mb: 2
        }}
      >
        {t('videoPlayer', 'routeSelection')}
        {favoriteRoutes.length > 0 && (
          <Typography 
            component="span" 
            variant="caption" 
            sx={{ 
              ml: 1, 
              color: 'text.secondary',
              fontWeight: 400
            }}
          >
            ({t('common', 'multipleFavoriteRoutes')})
          </Typography>
        )}
      </Typography>
      <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ gap: 1 }}>
        {sortedRoutesWithIndices.map(({ route, originalIndex }) => (
          <Box key={route} sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant={selectedRoute === originalIndex ? "contained" : "outlined"}
              size="medium"
              onClick={() => onRouteSelect(originalIndex)}
              sx={{
                color: selectedRoute === originalIndex ? 'white' : 'text.primary',
                borderColor: favoriteRoutes.includes(route) ? 'error.light' : 'grey.300',
                position: 'relative',
                pl: favoriteRoutes.includes(route) ? 2.5 : undefined,
                '&:hover': {
                  bgcolor: selectedRoute === originalIndex 
                    ? 'primary.dark'
                    : alpha(theme.palette.common.black, 0.1),
                  borderColor: favoriteRoutes.includes(route) ? 'error.main' : undefined,
                },
              }}
            >
              {favoriteRoutes.includes(route) && (
                <FavoriteIcon 
                  fontSize="small" 
                  sx={{ 
                    position: 'absolute',
                    left: 8,
                    fontSize: '0.75rem',
                    color: selectedRoute === originalIndex ? 'white' : 'error.main',
                    opacity: 0.8
                  }} 
                />
              )}
              {t('videoPlayer', route)}
            </Button>
            <Tooltip title={favoriteRoutes.includes(route) ? t('common', 'removeFavorite') : t('common', 'setAsFavoriteRoute')}>
              <IconButton 
                size="small" 
                onClick={() => handleToggleFavorite(route)}
                sx={{ 
                  ml: 0.5,
                  color: favoriteRoutes.includes(route) ? 'error.main' : 'action.active'
                }}
              >
                {favoriteRoutes.includes(route) ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />}
              </IconButton>
            </Tooltip>
          </Box>
        ))}
      </Stack>
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity="success" 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RouteSelector; 