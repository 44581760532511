import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  CircularProgress,
  Alert,
  useTheme,
  Tabs,
  Tab,
  Paper,
  Pagination,
  useMediaQuery,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import MovieIcon from '@mui/icons-material/Movie';
import TvIcon from '@mui/icons-material/Tv';
import { useLanguage } from '../contexts/LanguageContext';

const TMDB_API_KEY = process.env.REACT_APP_TMDB_API_KEY;
const TMDB_BASE_URL = 'https://api.themoviedb.org/3';
const TMDB_IMAGE_BASE_URL = 'https://image.tmdb.org/t/p/w500';

const ContentDiscovery = ({ onMovieSelect }) => {
  const theme = useTheme();
  const { t } = useLanguage();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState('trending');
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [mediaType, setMediaType] = useState('movie');
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState('');
  const [sortBy, setSortBy] = useState('popularity.desc');

  // Fetch genres from TMDB
  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const response = await fetch(
          `${TMDB_BASE_URL}/genre/${mediaType}/list?api_key=${TMDB_API_KEY}&language=zh-CN`
        );
        const data = await response.json();
        setGenres(data.genres || []);
      } catch (err) {
        console.error('Error fetching genres:', err);
      }
    };

    fetchGenres();
  }, [mediaType]);

  const fetchMovies = async (endpoint, params = {}) => {
    try {
      setLoading(true);
      setError(null);

      if (!TMDB_API_KEY || TMDB_API_KEY === 'YOUR_TMDB_API_KEY') {
        throw new Error(t('discovery.apiKeyError'));
      }

      // Always use discover endpoint when filters are active
      let finalEndpoint;
      if (selectedGenre || sortBy !== 'popularity.desc') {
        finalEndpoint = `/discover/${mediaType}`;
      } else {
        // Use specific endpoints only when no filters are active
        if (activeTab === 'trending') {
          finalEndpoint = `/trending/${mediaType}/week`;
        } else if (activeTab === 'topRated') {
          finalEndpoint = `/${mediaType}/top_rated`;
        } else if (activeTab === 'upcoming') {
          finalEndpoint = mediaType === 'tv' ? '/tv/on_the_air' : '/movie/upcoming';
        } else {
          finalEndpoint = `/discover/${mediaType}`;
        }
      }

      const queryParams = new URLSearchParams({
        api_key: TMDB_API_KEY,
        language: 'zh-CN',
        page: page.toString(),
        ...params,
      });

      // Add filters
      if (selectedGenre) {
        queryParams.append('with_genres', selectedGenre);
      }

      // Add sort parameter
      queryParams.append('sort_by', sortBy);

      // Add region and language preferences for better results
      if (mediaType === 'movie') {
        queryParams.append('region', 'CN');
        queryParams.append('with_original_language', 'zh');
      }

      // Add specific parameters based on active tab
      if (activeTab === 'topRated') {
        queryParams.append('vote_count.gte', '100');
      }

      console.log('Fetching from:', finalEndpoint, 'with params:', Object.fromEntries(queryParams));

      const response = await fetch(`${TMDB_BASE_URL}${finalEndpoint}?${queryParams}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(t('discovery.fetchError', { message: errorData.status_message }));
      }

      const data = await response.json();
      if (!data.results || data.results.length === 0) {
        throw new Error(t('discovery.noMoviesFound'));
      }

      const processedResults = data.results.map(item => ({
        id: item.id,
        title: item.title || item.name,
        poster_path: item.poster_path,
        vote_average: item.vote_average,
        release_date: item.release_date || item.first_air_date,
        media_type: mediaType
      }));

      setMovies(processedResults);
      setTotalPages(Math.min(data.total_pages, 10));
    } catch (err) {
      console.error('Error fetching content:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Update useEffect dependencies to properly react to all changes
  useEffect(() => {
    const endpoints = {
      trending: '/trending/all/week',
      topRated: '/movie/top_rated',
      upcoming: '/movie/upcoming',
      discover: '/discover/movie',
    };

    fetchMovies(endpoints[activeTab]);
  }, [activeTab, mediaType, selectedGenre, sortBy, page]); // Keep page in dependencies to react to page changes

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(1); // Reset page when changing tabs
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleMediaTypeChange = (event, newMediaType) => {
    if (newMediaType !== null) {
      setMediaType(newMediaType);
      setSelectedGenre(''); // Reset genre when changing media type
      setPage(1); // Reset page when changing media type
    }
  };

  const sortOptions = [
    { value: 'popularity.desc', label: '人气（从高到低）' },
    { value: 'popularity.asc', label: '人气（从低到高）' },
    { value: 'vote_average.desc', label: '评分（从高到低）' },
    { value: 'vote_average.asc', label: '评分（从低到高）' },
    { value: 'primary_release_date.desc', label: '上映日期（从新到旧）' },
    { value: 'primary_release_date.asc', label: '上映日期（从旧到新）' },
    { value: 'revenue.desc', label: '票房（从高到低）' },
    { value: 'vote_count.desc', label: '投票数（从高到低）' }
  ];

  const renderMovieCard = (movie) => (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'scale(1.02)',
          cursor: 'pointer',
          boxShadow: (theme) => theme.shadows[8],
        },
        borderRadius: 2,
        overflow: 'hidden',
      }}
      onClick={() => {
        if (onMovieSelect) {
          window.location.href = `/search?q=${encodeURIComponent(movie.title)}`;
        }
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', paddingTop: '150%' }}>
        <CardMedia
          component="img"
          image={movie.poster_path ? `${TMDB_IMAGE_BASE_URL}${movie.poster_path}` : '/placeholder.jpg'}
          alt={movie.title}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
      <CardContent sx={{ flexGrow: 1, p: 2 }}>
        <Typography variant="h6" noWrap title={movie.title} sx={{ mb: 1 }}>
          {movie.title}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            icon={<StarIcon sx={{ fontSize: '1rem' }} />}
            label={movie.vote_average.toFixed(1)}
            color="primary"
          />
          <Chip
            size="small"
            label={new Date(movie.release_date).getFullYear()}
            variant="outlined"
          />
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ mb: isMobile ? 2 : 4 }}>
        {/* Media Type Toggle and Filters */}
        <Paper 
          elevation={0} 
          sx={{ 
            p: isMobile ? 0 : 2, 
            mb: isMobile ? 1 : 3,
            backgroundColor: isMobile ? 'transparent' : 'paper'
          }}
        >
          <Stack spacing={isMobile ? 0.5 : 2}>
            <ToggleButtonGroup
              value={mediaType}
              exclusive
              onChange={handleMediaTypeChange}
              aria-label="media type"
              size={isMobile ? "small" : "medium"}
              sx={{
                width: isMobile ? '100%' : 'auto',
                '& .MuiToggleButton-root': {
                  flex: isMobile ? 1 : 'initial',
                  borderRadius: isMobile ? 0 : 1,
                  backgroundColor: isMobile ? 'background.paper' : undefined,
                  border: isMobile ? 'none' : undefined,
                  borderBottom: isMobile ? '1px solid rgba(0, 0, 0, 0.12)' : undefined,
                  '&.Mui-selected': {
                    borderBottom: isMobile ? '2px solid primary.main' : undefined,
                  }
                }
              }}
            >
              <ToggleButton value="movie" aria-label="movie">
                <MovieIcon sx={{ mr: isMobile ? 0 : 1, fontSize: isMobile ? '1.2rem' : '1.5rem' }} /> 
                {!isMobile && '电影'}
              </ToggleButton>
              <ToggleButton value="tv" aria-label="tv">
                <TvIcon sx={{ mr: isMobile ? 0 : 1, fontSize: isMobile ? '1.2rem' : '1.5rem' }} /> 
                {!isMobile && '电视剧'}
              </ToggleButton>
            </ToggleButtonGroup>

            <Stack 
              direction={isMobile ? "column" : "row"} 
              spacing={isMobile ? 0 : 2}
              sx={{ width: '100%' }}
            >
              <FormControl 
                size="small" 
                sx={{ 
                  minWidth: isMobile ? '100%' : 200,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '16px',
                    backgroundColor: isMobile ? 'background.paper' : undefined,
                  },
                  '& .MuiSelect-select': {
                    py: isMobile ? 1.5 : 1,
                  },
                  mb: isMobile ? 1 : 0
                }}
              >
                <InputLabel>类型</InputLabel>
                <Select
                  value={selectedGenre}
                  label="类型"
                  onChange={(e) => {
                    setSelectedGenre(e.target.value);
                    setPage(1);
                  }}
                >
                  <MenuItem value="">全部</MenuItem>
                  {genres.map((genre) => (
                    <MenuItem key={genre.id} value={genre.id}>
                      {genre.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl 
                size="small" 
                sx={{ 
                  minWidth: isMobile ? '100%' : 200,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '16px',
                    backgroundColor: isMobile ? 'background.paper' : undefined,
                  },
                  '& .MuiSelect-select': {
                    py: isMobile ? 1.5 : 1,
                  },
                  mb: isMobile ? 1 : 0
                }}
              >
                <InputLabel>排序方式</InputLabel>
                <Select
                  value={sortBy}
                  label="排序方式"
                  onChange={(e) => {
                    setSortBy(e.target.value);
                    setPage(1);
                  }}
                >
                  {sortOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Paper>

        {/* Tabs */}
        <Paper 
          elevation={0} 
          sx={{ 
            mb: isMobile ? 1 : 3,
            backgroundColor: isMobile ? 'transparent' : 'paper'
          }}
        >
          <Box sx={{ 
            borderBottom: isMobile ? 0 : 1, 
            borderColor: 'divider',
            backgroundColor: isMobile ? 'background.paper' : undefined,
          }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons={isMobile ? "auto" : false}
              sx={{ 
                minHeight: isMobile ? 40 : 48,
                '& .MuiTab-root': {
                  minWidth: isMobile ? 'auto' : 120,
                  fontSize: isMobile ? '0.875rem' : 'inherit',
                  px: isMobile ? 2 : 3,
                  py: isMobile ? 0 : 1,
                  minHeight: isMobile ? 40 : 48
                },
                '& .MuiTabs-indicator': {
                  height: isMobile ? 2 : 2
                }
              }}
            >
              <Tab
                label={isMobile ? "热门" : "热门内容"}
                value="trending"
                icon={<TrendingUpIcon sx={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }} />}
                iconPosition="start"
              />
              <Tab
                label={isMobile ? "评分" : "评分最高"}
                value="topRated"
                icon={<StarIcon sx={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }} />}
                iconPosition="start"
              />
              <Tab
                label={isMobile ? "新片" : "即将上映"}
                value="upcoming"
                icon={<NewReleasesIcon sx={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }} />}
                iconPosition="start"
              />
              <Tab
                label="发现"
                value="discover"
                icon={<MovieIcon sx={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }} />}
                iconPosition="start"
              />
            </Tabs>
          </Box>
        </Paper>

        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
            <Typography variant="body2" sx={{ ml: 2 }}>
              正在加载内容...
            </Typography>
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            错误：{error}
          </Alert>
        ) : (
          <>
            <Grid 
              container 
              spacing={isMobile ? 1 : 2}
              sx={{
                mx: isMobile ? -1 : 0,
                mt: isMobile ? 1 : 2
              }}
            >
              {movies.map((movie) => (
                <Grid 
                  item 
                  xs={6} 
                  sm={4} 
                  md={3} 
                  lg={2.4} 
                  key={movie.id}
                  sx={{ 
                    px: isMobile ? 0.25 : 1,
                    mb: isMobile ? 0.5 : 2
                  }}
                >
                  {renderMovieCard(movie)}
                </Grid>
              ))}
            </Grid>
            
            {totalPages > 1 && (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                mt: isMobile ? 1 : 4,
                '& .MuiPagination-ul': {
                  flexWrap: 'nowrap',
                }
              }}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size={isMobile ? "small" : "medium"}
                  siblingCount={isMobile ? 0 : 1}
                  boundaryCount={isMobile ? 1 : 2}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ContentDiscovery; 