import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Button, Paper, Stack, useTheme, alpha, Menu, MenuItem, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AndroidIcon from '@mui/icons-material/Android';
import ChatIcon from '@mui/icons-material/Chat';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Layout from '../components/Layout';
import { useLanguage } from '../contexts/LanguageContext';
import { getLatestAppVersion } from '../services/api';

const AppDownload = () => {
  const theme = useTheme();
  const { t } = useLanguage();
  const [anchorEl, setAnchorEl] = useState(null);
  const [appVersion, setAppVersion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [iOSDialogOpen, setIOSDialogOpen] = useState(false);
  const [iosAnchorEl, setIOSAnchorEl] = useState(null);
  const [selfSignDialogOpen, setSelfSignDialogOpen] = useState(false);

  useEffect(() => {
    const fetchAppVersion = async () => {
      try {
        setLoading(true);
        const versionData = await getLatestAppVersion();
        setAppVersion(versionData);
        setError(null);
      } catch (err) {
        console.error('Error fetching app version:', err);
        setError(err.message || 'Failed to load app version information');
      } finally {
        setLoading(false);
      }
    };

    fetchAppVersion();
  }, []);

  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadOption = (url) => {
    window.open(url, '_blank');
    handleDownloadClose();
  };

  const handleIOSDownloadClick = () => {
    if (appVersion?.ipa_url) {
      setIOSDialogOpen(true);
    }
  };

  const handleIOSDialogClose = () => {
    setIOSDialogOpen(false);
  };

  const handleIOSConfirm = () => {
    if (appVersion?.ipa_url) {
      window.open(appVersion.ipa_url, '_blank');
    }
    setIOSDialogOpen(false);
  };

  const handleIOSMenuOpen = (event) => {
    setIOSAnchorEl(event.currentTarget);
  };

  const handleIOSMenuClose = () => {
    setIOSAnchorEl(null);
  };

  return (
    <Layout>
      <Container 
        maxWidth="lg" 
        sx={{ 
          pt: { xs: 8, sm: 9, md: 10 },
          pb: { xs: 4, sm: 6, md: 8 },
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 4, sm: 6 }
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: 700,
              mb: 2,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
            }}
          >
            {t('app', 'title')}
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{
              maxWidth: '800px',
              mx: 'auto',
              mb: 4,
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' }
            }}
          >
            {t('app', 'subtitle')}
          </Typography>
        </Box>

        <Stack spacing={4} alignItems="center">
          {/* Preview Images */}
          <Box sx={{ 
            width: '100%',
            maxWidth: '1200px',
            mx: 'auto',
            px: { xs: 2, sm: 4 }
          }}>
            <Stack spacing={3}>
              {[1, 2, 3].map((num) => (
                <Paper
                  key={num}
                  elevation={4}
                  sx={{
                    width: '100%',
                    borderRadius: 2,
                    overflow: 'hidden',
                    transition: 'transform 0.3s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                    }
                  }}
                  onClick={() => handleImageClick(`/images/app-preview-${num}.png`)}
                >
                  <Box
                    component="img"
                    src={`/images/app-preview-${num}.png`}
                    alt={`247看 App Preview ${num}`}
                    sx={{
                      width: '100%',
                      height: 'auto',
                      display: 'block',
                      aspectRatio: '3/2',
                      objectFit: 'cover',
                    }}
                  />
                </Paper>
              ))}
            </Stack>
          </Box>

          {/* Features and Download Section */}
          <Paper
            elevation={0}
            sx={{
              p: { xs: 3, sm: 4, md: 5 },
              borderRadius: 4,
              bgcolor: alpha(theme.palette.primary.main, 0.04),
              border: '1px solid',
              borderColor: 'divider',
              maxWidth: { xs: '800px', lg: '1000px', xl: '1200px' },
              mx: 'auto',
              width: '100%',
              textAlign: 'center'
            }}
          >
            <Stack spacing={4} alignItems="center">
              {loading ? (
                <CircularProgress />
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : (
                <>
                  <Box sx={{ textAlign: 'center', maxWidth: '600px', mx: 'auto' }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 600,
                        mb: 2,
                        fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' }
                      }}
                    >
                      {appVersion?.force_update ? t('app', 'updateRequired') : t('app', 'comingSoon')}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ mb: 1 }}
                    >
                      {t('app', 'description')}
                    </Typography>
                    {appVersion && (
                      <Typography 
                        variant="subtitle1" 
                        color="primary"
                        sx={{ 
                          fontWeight: 500,
                          mb: 1
                        }}
                      >
                        最新版本号：v{appVersion.version_name || '1.0.0'}
                      </Typography>
                    )}
                  </Box>

                  {/* Feature List */}
                  <Box sx={{ 
                    width: '100%',
                    maxWidth: { xs: '500px', md: '700px', lg: '800px' },
                    mx: 'auto'
                  }}>
                    <Stack 
                      spacing={2} 
                      sx={{ 
                        mb: 4,
                        px: { xs: 2, sm: 4 }
                      }}
                    >
                      {t('app', 'features').split('\n').map((feature, index) => (
                        <Box key={index} sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 2,
                          justifyContent: 'flex-start',
                          width: '100%'
                        }}>
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              borderRadius: '50%',
                              bgcolor: 'primary.main',
                              flexShrink: 0,
                            }}
                          />
                          <Typography sx={{ textAlign: 'left' }}>{feature}</Typography>
                        </Box>
                      ))}
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                      <Button
                        variant="contained"
                        size="large"
                        startIcon={<AndroidIcon />}
                        fullWidth
                        onClick={handleDownloadClick}
                        sx={{ 
                          py: 1.5,
                          borderRadius: 2,
                          fontSize: '1.1rem'
                        }}
                      >
                        {t('app', 'androidApp')}
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleDownloadClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        {appVersion?.apk_url && (
                          <MenuItem onClick={() => handleDownloadOption(appVersion.apk_url)}>
                            <CloudDownloadIcon sx={{ mr: 1 }} />
                            国内推荐下载
                          </MenuItem>
                        )}
                        <MenuItem onClick={() => handleDownloadOption('https://url47.ctfile.com/f/14702447-1487077274-91d184?p=5683')}>
                          <CloudDownloadIcon sx={{ mr: 1 }} />
                          国内推荐下载（密码5683）
                        </MenuItem>
                        <MenuItem onClick={() => handleDownloadOption('https://drive.google.com/file/d/125p9l0-oxSD6hRONYIT4Ob7opklDX3W5/view?usp=sharing')}>
                          <CloudDownloadIcon sx={{ mr: 1 }} />
                          国外推荐下载
                        </MenuItem>
                      </Menu>
                      <Button
                        variant="contained"
                        size="large"
                        disabled={!appVersion?.ipa_url}
                        startIcon={<PhoneIphoneIcon />}
                        fullWidth
                        onClick={handleIOSMenuOpen}
                        sx={{ 
                          py: 1.5,
                          borderRadius: 2,
                          fontSize: '1.1rem'
                        }}
                      >
                        {t('app', 'iosApp')}
                      </Button>
                    </Stack>
                  </Box>
                </>
              )}

              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  {t('app', 'feedbackPrompt')}
                </Typography>
                <Button
                  component={Link}
                  to="/guestbook"
                  variant="outlined"
                  startIcon={<ChatIcon />}
                  sx={{ 
                    borderRadius: 2,
                    px: 3
                  }}
                >
                  {t('app', 'gotoGuestbook')}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Stack>
      </Container>

      {/* iOS Installation Trust Dialog */}
      <Dialog
        open={iOSDialogOpen}
        onClose={handleIOSDialogClose}
        aria-labelledby="ios-trust-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="ios-trust-dialog-title">
          信任企业级应用安装说明
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body1">
              您即将下载并安装企业级iOS应用。安装后，首次打开应用时，iOS系统会提示"未受信任的企业级开发者"。
            </Typography>
            
            <Typography variant="body1">
              请按照以下步骤信任应用：
            </Typography>
            
            <Box component="ol" sx={{ pl: 2 }}>
              <li>
                <Typography>打开"设置"&gt;"通用"&gt;"VPN与设备管理"</Typography>
              </li>
              <li>
                <Typography>在"企业级App"部分，选择"247看"</Typography>
              </li>
              <li>
                <Typography>点击"信任"</Typography>
              </li>
              <li>
                <Typography>在弹出的确认对话框中，点击"信任"确认</Typography>
              </li>
            </Box>
            
            <Typography 
              variant="body1" 
              sx={{ 
                mt: 2, 
                bgcolor: 'warning.light', 
                p: 1.5, 
                borderRadius: 1,
                fontWeight: 'medium'
              }}
            >
              <strong>重要提示：</strong> iOS 17或以下版本只需点击"信任"；iOS 18或以上版本需要点击"信任"并重启设备。这是苹果公司的安全措施，我无法改变，对此带来的不便深表歉意。
            </Typography>
            
            <MuiLink 
              href="https://support.apple.com/zh-cn/118254" 
              target="_blank"
              rel="noopener noreferrer"
              display="flex"
              alignItems="center"
              sx={{ mt: 1 }}
            >
              <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
              Apple官方安装指南：在iOS、iPadOS上安装自定企业级App
            </MuiLink>
            
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              请确认您已阅读并理解上述安装说明。点击"确认下载"继续。
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleIOSDialogClose}>取消</Button>
          <Button onClick={handleIOSConfirm} variant="contained">确认下载</Button>
        </DialogActions>
      </Dialog>

      {/* Self-Sign Dialog */}
      <Dialog
        open={selfSignDialogOpen}
        onClose={() => setSelfSignDialogOpen(false)}
        aria-labelledby="self-sign-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="self-sign-dialog-title">
          自签方式安装说明
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body1">
              如果您担心隐私问题，可以选择自签方式安装。此方式：
            </Typography>
            <Box component="ul" sx={{ pl: 2 }}>
              <li><Typography>我们不会收集任何用户数据</Typography></li>
              <li><Typography>无法控制您的设备</Typography></li>
              <li><Typography>苹果的安全提示属于正常验证流程</Typography></li>
            </Box>
            <Typography variant="body1">
              安装步骤：
            </Typography>
            <Box component="ol" sx={{ pl: 2 }}>
              <li><Typography>点击下方链接获取安装文件</Typography></li>
              <li><Typography>按照视频教程进行自签安装</Typography></li>
            </Box>
            <MuiLink 
              href="https://philip.lanzout.com/iF8lt2sfnq7c" 
              target="_blank"
              rel="noopener noreferrer"
              display="flex"
              alignItems="center"
            >
              <CloudDownloadIcon fontSize="small" sx={{ mr: 0.5 }} />
              自签安装包下载
            </MuiLink>
            <MuiLink 
              href="https://search.bilibili.com/all?keyword=%E8%87%AA%E7%AD%BE%E6%95%99%E7%A8%8B" 
              target="_blank"
              rel="noopener noreferrer"
              display="flex"
              alignItems="center"
            >
              <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
              B站自签安装视频教程
            </MuiLink>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelfSignDialogOpen(false)}>取消</Button>
          <Button 
            onClick={() => {
              window.open('https://lz.qaiu.top/parser?url=https://philip.lanzout.com/iF8lt2sfnq7c', '_blank');
              setSelfSignDialogOpen(false);
            }} 
            variant="contained"
          >
            确认下载
          </Button>
        </DialogActions>
      </Dialog>

      {/* iOS Download Menu */}
      <Menu
        anchorEl={iosAnchorEl}
        open={Boolean(iosAnchorEl)}
        onClose={handleIOSMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => {
          handleIOSMenuClose();
          setIOSDialogOpen(true);
        }}>
          <CloudDownloadIcon sx={{ mr: 1 }} />
          标准企业证书安装
        </MenuItem>
        <MenuItem onClick={() => {
          handleIOSMenuClose();
          setSelfSignDialogOpen(true);
        }}>
          <CloudDownloadIcon sx={{ mr: 1 }} />
          自签方式安装（隐私保护）
        </MenuItem>
      </Menu>
    </Layout>
  );
};

export default AppDownload; 