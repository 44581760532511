import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  useTheme,
  alpha,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import { useLanguage } from '../contexts/LanguageContext';
import { Link } from 'react-router-dom';

// Simple hash function for announcement content
const hashString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash.toString();
};

const updateLogs = [
  {
    date: '2025/3/8',
    title: '弹幕库更新',
    updates: [
      '更新弹幕库，提升弹幕系统稳定性',
      '增加弹幕功能，支持更多互动方式',
      '优化弹幕显示效果，提升观影体验',
      '修复部分视频弹幕同步问题'
    ]
  },
  {
    date: '2025/3/1',
    title: '去广告插件更新与收藏路线功能',
    updates: [
      '更新去广告插件，提升广告识别准确率',
      '新增收藏路线功能，支持将喜爱的线路加入收藏',
      '优化播放逻辑，优先播放用户收藏的线路',
      '改进线路切换体验，提升观影流畅度'
    ]
  },
  {
    date: '2025/2/19',
    title: '分页功能修复与优化',
    updates: [
      '修复内容浏览页面分页加载问题',
      '优化内容加载性能，提升页面响应速度',
      '改进移动端分页加载体验',
      '修复分类筛选后分页重置问题'
    ]
  },
  {
    date: '2025/2/9',
    title: '播放器性能优化',
    updates: [
      '更新了去广告插件以解决视频卡顿/缓冲问题，请测试反馈',
      '修复 Chromecast 投屏按钮无法点击的问题',
      '优化播放器性能，提升观看流畅度'
    ]
  },
  {
    date: '2025/2/3',
    title: '功能优化与界面改进',
    updates: [
      '优化观看历史显示位置，移出搜索框区域',
      '修复视频播放器全屏模式问题',
      '新增视频下载功能页面',
    ]
  },
  {
    date: '2025/1/17',
    title: '功能修复与界面优化',
    updates: [
      '修复移动端导航栏观看历史功能',
      '修复观看页面切换剧集时的时间同步问题',
      '手机加回来观看历史功能',
      '重新组织观看页面的描述部分布局',
      '更多小功能优化，等你发现，有问题请反馈',
    ]
  },
  {
    date: '2025/1/14',
    title: '播放器功能增强',
    updates: [
      '新增备用播放器，解决设备兼容性问题',
      '优化上一集/下一集按钮功能，提升观看体验'
    ]
  },
  {
    date: '2025/1/6',
    title: '筛选功能增强与移动端优化',
    updates: [
      '增强浏览页面基础筛选功能，支持多选筛选',
      '新增年份范围选择器，方便按时间段筛选',
      '全新移动端搜索体验：全屏搜索界面、优化触摸交互、更清晰的搜索建议展示',
      '改进移动端导航与视觉效果：更大的触控区域、平滑过渡动画',
      '重新组织公告界面布局，优化阅读体验',
      '优化筛选器在移动设备上的显示效果'
    ]
  },
  {
    date: '2025/1/2',
    title: '功能修复更新',
    updates: [
      '去广告插件已更新，插播广告应该已全部去除',
      '修复播放器全屏按钮缺失问题，优化上下集按钮',
      '请勿误触投屏按钮，因为投屏功能暂不支持去广告功能，会导致视频刷新使用原始m3u8源从而出现广告。如果不小心点击，请刷新页面即可恢复去广告功能',
      '如有其他问题，请到留言区反馈，感谢您的理解'
    ]
  },
  {
    date: '2025/1/1',
    title: '系统优化与功能增强',
    updates: [
      '修复低分辨率 PWA 图标显示问题（可能需要一段时间更新缓存）',
      '优化小屏幕设备导航栏文字显示',
      '增强重名影片合并，如有影片信息错误请反馈',
      '播放器增加上一集、下一集按钮',
      '新增链接点击跳转功能',
      '新增高清无广告线路，例如：https://247kan.com/watch/29636?route=4&ep=1',
      '2025年我们将持续增加更多视频，感谢您的使用，祝各位新年快乐！'
    ]
  },
  {
    date: '2024/12/29',
    title: '功能增强与界面优化',
    updates: [
      '在播放页面，新增使用帮助与提示',
      '在浏览页面，新增随机视频推荐按钮',
      '优化搜索页面的结果展示',
      '修复观看页面的评论层级显示',
      '简化浏览页面的筛选界面'
    ]
  },
  {
    date: '2024/12/28',
    title: '功能优化与界面改进',
    updates: [
      '优化热门内容排序算法，提升热门内容展示效果',
      '修复搜索时可能遇到网络错误的提示',
      '修复 AirPlay 投屏只有声音没有画面的问题',
      '优化浏览页面分类栏布局',
      '优化地区筛选功能',
      '新增影片信息错误报告功能',
      '改进更新日志界面布局'
    ]
  },
  {
    date: '2024/12/27',
    title: '播放页面优化与功能增强',
    updates: [
      '修复页面跳转时滚动条位置不一致的问题',
      '重新组织播放页面布局和留言系统，优化用户体验',
      '增强剧集选择功能，支持正序/倒序切换和展开/收起',
      '新增提示：当所有线路无法播放时建议使用 Chrome 浏览器',
      '优化搜索功能：当搜索失败时自动重试（解决因请求失败导致的无结果问题）',
      '新增支持使用鼠标中键打开影视详情页'
    ]
  },
  {
    date: '2024/12/24',
    title: '搜索系统升级与界面优化',
    updates: [
      '新增智能搜索建议功能',
      '添加搜索历史记录功能',
      '优化浏览页面热门内容排序',
      '改进影片详情页视觉效果',
      '升级热门视频排序算法'
    ]
  },
  {
    date: '2024/12/19',
    title: '系统优化更新',
    updates: [
      '增强搜索功能，支持模糊搜索，提升搜索速度和准确度',
      '修复弹幕系统缺失剧集的问题',
      '修复观看历史重定向空路由的问题',
      '优化蓝光线路的命名显示',
      '优化播放器按钮在不同尺寸下的显示效果',
      '增强平板设备的响应式布局设计'
    ]
  },
  {
    date: '2024/12/13',
    title: '功能优化与界面更新',
    updates: [
      '优化浏览页面的筛选面板，提供更直观的操作体验',
      '修复弹幕系统，确保弹幕正确发送和显示',
      '更新智能去广告插件，提升广告识别准确率',
      '优化验证码功能，提供更流畅的用户验证体验',
      '新增自动/手动播放下集功能，支持连续观看',
      '重新设计公告和更新日志界面，提供更好的阅读体验',
      '修复搜索结果显示问题和翻译问题'
    ]
  },
  {
    date: '2024/12/10',
    title: '重大功能更新',
    updates: [
      '推出会员系统：支持收藏、稍后观看、观看历史等功能',
      '优化去广告算法：显著提升准确率，减少误判情况',
      '升级搜索引擎：改进搜索算法，提供更准确的搜索结果',
      '新增互动弹幕：支持实时互动，Chrome浏览器可获得最佳体验',
      '支持多种投屏：新增 AirPlay、Chromecast、Miracast 投屏功能'
    ]
  }
];

const announcementContent = {
  announcement: {
    title: '重要通知：系统维护与线路使用提示 (2025/3/24)',
    content: [
      '亲爱的用户，我有以下重要通知：',
      '1. 我正在修复系统和app中的各种bug，感谢您的耐心等待',
      '2. 247看专线目前正在维护中，暂时无法使用',
      '3. 请您暂时使用其他线路观看内容',
      '4. 建议不要使用蓝光H线路，该线路含有18+广告内容',
      '我将尽快完成维护工作。感谢您的理解与支持！',
      '祝您观影愉快！'
    ],
    action: {
      text: '安卓/IOS APP下载',
      url: '/app'
    }
  },
  features: {
    title: '欢迎来到247看 247kan.com',
    welcome: '感谢你选择247看！这是我开发的一个免费观影平台，希望能给大家带来舒适的观影体验。',
    highlights: [
      '完全免费无广告：提供最纯净的观影',
      '全新会员系统：支持收藏、观看历史等功能',
      '智能投屏：支持 AirPlay、Chromecast、Miracast',
      '高清视频：支持1080P视频播放',
      '智能去广告：采用先进算法，提供清爽观影体验'
    ],
    tips: [
      '投屏功能需要设备在同一网络下使用',
      '电视端投屏暂不支持去广告和弹幕功能',
      'Chrome浏览器可获得最佳观影体验'
    ],
    future: '我计划开发247看的移动应用，会支持安卓、iOS和智能电视平台。但现在会先专注于把网页版做好，确保稳定性和完善度，再逐步开发应用。',
    closing: '作为一个个人开发的非营利项目，我会持续优化和维护。欢迎在留言板分享你的观影体验和建议。祝观影愉快！'
  }
};

const AnnouncementOverlay = (props, ref) => {
  const theme = useTheme();
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('announcement');

  useEffect(() => {
    // Get current hashes for each section
    const currentAnnouncementHash = hashString(JSON.stringify(announcementContent.announcement));
    const currentFeaturesHash = hashString(JSON.stringify(announcementContent.features));
    const currentUpdateHash = hashString(JSON.stringify(updateLogs));
    
    // Get stored hashes
    const storedAnnouncementHash = localStorage.getItem('announcementContentHash');
    const storedFeaturesHash = localStorage.getItem('featuresContentHash');
    const storedUpdateHash = localStorage.getItem('updateLogsHash');
    
    // Check if any content has changed
    const hasAnnouncementChanged = currentAnnouncementHash !== storedAnnouncementHash;
    const hasFeaturesChanged = currentFeaturesHash !== storedFeaturesHash;
    const hasUpdatesChanged = currentUpdateHash !== storedUpdateHash;
    
    // Update all hashes in local storage at once
    localStorage.setItem('announcementContentHash', currentAnnouncementHash);
    localStorage.setItem('featuresContentHash', currentFeaturesHash);
    localStorage.setItem('updateLogsHash', currentUpdateHash);
    
    // Show dialog only once with priority order: announcement > updates > features
    if (hasAnnouncementChanged) {
      setOpen(true);
      setActiveTab('announcement');
    } else if (hasUpdatesChanged) {
      setOpen(true);
      setActiveTab('updates');
    } else if (hasFeaturesChanged) {
      setOpen(true);
      setActiveTab('features');
    }
  }, []);

  // Add URL detection and rendering function
  const renderTextWithLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    
    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: theme.palette.primary.main,
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  // Add this function to expose the open method
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Add this to expose the open method through a ref
  useImperativeHandle(ref, () => ({
    openAnnouncement: handleOpen
  }));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: 'background.paper',
          backgroundImage: 'none',
          borderRadius: 3,
          boxShadow: theme.shadows[4],
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          bgcolor: alpha(theme.palette.primary.main, 0.08),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography
            variant="h6"
            component="span"
            sx={{
              fontWeight: 700,
              color: 'text.primary',
              fontSize: { xs: '1rem', sm: '1.1rem' },
              cursor: 'pointer',
              borderBottom: activeTab === 'announcement' ? `2px solid ${theme.palette.primary.main}` : 'none',
              pb: 0.5,
            }}
            onClick={() => setActiveTab('announcement')}
          >
            {t('common', 'announcement')}
          </Typography>
          <Typography
            variant="h6"
            component="span"
            sx={{
              fontWeight: 700,
              color: 'text.primary',
              fontSize: { xs: '1rem', sm: '1.1rem' },
              cursor: 'pointer',
              borderBottom: activeTab === 'features' ? `2px solid ${theme.palette.primary.main}` : 'none',
              pb: 0.5,
            }}
            onClick={() => setActiveTab('features')}
          >
            网站功能
          </Typography>
          <Typography
            variant="h6"
            component="span"
            sx={{
              fontWeight: 700,
              color: 'text.primary',
              fontSize: { xs: '1rem', sm: '1.1rem' },
              cursor: 'pointer',
              borderBottom: activeTab === 'updates' ? `2px solid ${theme.palette.primary.main}` : 'none',
              pb: 0.5,
            }}
            onClick={() => setActiveTab('updates')}
          >
            更新日志
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: 'text.secondary',
            '&:hover': {
              color: 'text.primary',
              bgcolor: alpha(theme.palette.primary.main, 0.12),
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {activeTab === 'announcement' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600, mb: 2 }}>
                {announcementContent.announcement.title}
              </Typography>
              {announcementContent.announcement.content.map((text, index) => (
                <Typography 
                  key={index} 
                  variant="body1" 
                  sx={{ 
                    color: 'text.primary', 
                    lineHeight: 1.6,
                    mb: index < announcementContent.announcement.content.length - 1 ? 2 : 0,
                    fontStyle: index === announcementContent.announcement.content.length - 1 ? 'italic' : 'normal',
                    color: index === announcementContent.announcement.content.length - 1 ? 'text.secondary' : 'text.primary'
                  }}
                >
                  {text}
                </Typography>
              ))}
              {announcementContent.announcement.action && (
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={announcementContent.announcement.action.url}
                  sx={{ mt: 3 }}
                  onClick={handleClose}
                >
                  {announcementContent.announcement.action.text}
                </Button>
              )}
            </Box>
          </Box>
        )}
        {activeTab === 'features' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 700, mb: 2 }}>
                {announcementContent.features.title}
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.primary', lineHeight: 1.6 }}>
                {announcementContent.features.welcome}
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                📱 功能亮点
              </Typography>
              <Typography variant="body1" component="div" sx={{ color: 'text.primary', pl: 2 }}>
                {announcementContent.features.highlights.map((highlight, index) => (
                  <React.Fragment key={index}>
                    • {highlight}<br/>
                  </React.Fragment>
                ))}
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                ⚡ 使用提示
              </Typography>
              <Typography variant="body1" component="div" sx={{ color: 'text.primary', pl: 2 }}>
                {announcementContent.features.tips.map((tip, index) => (
                  <React.Fragment key={index}>
                    • {tip}<br/>
                  </React.Fragment>
                ))}
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                🚀 未来规划
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.primary', pl: 2 }}>
                {announcementContent.features.future}
              </Typography>
            </Box>

            <Typography variant="body1" sx={{ color: 'text.secondary', mt: 1, fontStyle: 'italic' }}>
              {announcementContent.features.closing}
            </Typography>
          </Box>
        )}
        {activeTab === 'updates' && (
          <List sx={{ width: '100%', p: 0 }}>
            {updateLogs.map((log, index) => (
              <React.Fragment key={log.date}>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    px: 0,
                    pt: index === 0 ? 0 : 2,
                    pb: 2,
                    display: 'block',
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: index === 0 ? 30 : 44,
                      bottom: 16,
                      width: 2,
                      bgcolor: alpha(theme.palette.primary.main, 0.12),
                      borderRadius: 4,
                    }
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    mb: 1.5,
                    position: 'relative',
                    pl: 4
                  }}>
                    <Box
                      sx={{
                        position: 'absolute',
                        left: -4,
                        top: 20,
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        bgcolor: theme.palette.primary.main,
                        boxShadow: `0 0 0 4px ${alpha(theme.palette.primary.main, 0.1)}`,
                        zIndex: 1
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: 'text.secondary',
                        letterSpacing: 0.5,
                        mb: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}
                    >
                      <UpdateIcon sx={{ fontSize: 16 }} />
                      {log.date}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: 'text.primary',
                        fontWeight: 600,
                        fontSize: '1.1rem',
                        mb: 1.5,
                        transition: 'color 0.2s ease-in-out',
                        '&:hover': {
                          color: 'primary.main'
                        }
                      }}
                    >
                      {log.title}
                    </Typography>
                  </Box>
                  <List sx={{ 
                    pl: 4,
                    '& .MuiListItem-root': {
                      position: 'relative',
                      transition: 'all 0.2s ease-in-out',
                      py: 0.5,
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: -24,
                        top: '50%',
                        width: 16,
                        height: 2,
                        bgcolor: alpha(theme.palette.primary.main, 0.12),
                        transform: 'translateY(-50%)',
                      },
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.04),
                        '&::before': {
                          bgcolor: theme.palette.primary.main,
                          width: 20,
                        },
                        '& .MuiListItemText-primary': {
                          color: 'primary.main',
                          transform: 'translateX(4px)',
                        }
                      }
                    }
                  }}>
                    {log.updates.map((update, i) => (
                      <ListItem 
                        key={i} 
                        sx={{ 
                          px: 2, 
                          py: 0.75,
                          borderRadius: 1,
                        }}
                      >
                        <ListItemText
                          primary={renderTextWithLinks(update)}
                          sx={{
                            my: 0,
                            '& .MuiListItemText-primary': {
                              fontSize: '0.95rem',
                              color: 'text.primary',
                              lineHeight: 1.5,
                              transition: 'all 0.2s ease-in-out',
                            },
                            '& a': {
                              color: 'primary.main',
                              textDecoration: 'none',
                              '&:hover': {
                                textDecoration: 'underline'
                              }
                            }
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
                {index < updateLogs.length - 1 && (
                  <Divider sx={{ opacity: 0.08 }} />
                )}
              </React.Fragment>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};

// Export with forwardRef
export default forwardRef(AnnouncementOverlay); 