// Add import at the top of the file
import Cookies from 'js-cookie';
import axios from 'axios';
import { MeiliSearch } from 'meilisearch';

// Initialize MeiliSearch client
const MEILISEARCH_HOST = process.env.REACT_APP_MEILISEARCH_HOST || (window.location.protocol === 'https:' ? 'https://194.29.187.34:7700' : 'http://194.29.187.34:7700');
const MEILISEARCH_KEY = process.env.REACT_APP_MEILISEARCH_API_KEY;

// Only initialize if we have a valid host and key
if (!MEILISEARCH_HOST || !MEILISEARCH_KEY) {
  console.error('MeiliSearch configuration is missing required values:', {
    host: !!MEILISEARCH_HOST,
    apiKey: !!MEILISEARCH_KEY
  });
}

export const meilisearch = new MeiliSearch({
  host: MEILISEARCH_HOST,
  apiKey: MEILISEARCH_KEY,
  requestConfig: {
    mode: 'cors',
    credentials: 'omit',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
});

// Use environment variable or determine API URL based on hostname
const API_URL = process.env.NODE_ENV === 'development' 
  ? `${window.location.protocol}//${window.location.hostname}:3000/api`
  : '/api';

// Add API key to request headers
const getHeaders = () => {
  const headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  };

  // Add API key for sensitive endpoints
  const apiKey = process.env.REACT_APP_API_KEY;
  if (apiKey) {
    headers['x-api-key'] = apiKey;
  }

  return headers;
};

// Home page data
export const getHomePageData = async (signal) => {
  try {
    const response = await fetch(`${API_URL}/home`, {
      signal,
      headers: getHeaders()
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    if (data.status === 'error') {
      throw new Error(data.message || 'Failed to fetch home page data');
    }
    
    return data.data;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Fetch aborted');
      return null;
    }
    console.error('Failed to fetch home page data:', error);
    throw error;
  }
};

// Videos
export const getVideos = async (page = 1, limit = 20, filters = {}) => {
  const params = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
    ...filters
  });

  const response = await fetch(`${API_URL}/videos?${params}`, {
    headers: getHeaders()
  });
  const data = await response.json();
  if (data.status === 'error') throw new Error(data.message);
  return data.data;
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const searchVideos = async (query, page = 1, limit = 20, maxRetries = 3) => {
  let retries = 0;
  
  while (retries < maxRetries) {
    try {
      // Search using MeiliSearch
      const searchResults = await meilisearch.index('videos').search(query.trim(), {
        limit,
        offset: (page - 1) * limit,
        attributesToRetrieve: [
          'vod_id',
          'vod_name',
          'vod_pic',
          'vod_douban_score',
          'vod_year',
          'vod_remarks',
          'vod_actor',
          'vod_director',
          'vod_area',
          'vod_lang',
          'vod_content',
          'vod_class',
          'tmdb_id',
          'tmdb_rating',
          'tmdb_votes',
          'tmdb_poster',
          'tmdb_backdrop',
          'original_title',
          'alternative_titles',
          'overview'
        ]
      });
      
      return {
        videos: (searchResults.hits || []).map(video => ({
          ...video,
          vod_pic: video.vod_pic || '/default-poster.jpg',
          alternative_titles: video.alternative_titles || []
        })),
        total: searchResults.estimatedTotalHits || 0,
        current_page: page,
        total_pages: Math.ceil((searchResults.estimatedTotalHits || 0) / limit)
      };
    } catch (error) {
      retries++;
      console.error(`Search attempt ${retries} failed:`, error);
      
      // If we've exhausted all retries, throw the error
      if (retries === maxRetries) {
        if (error.message.includes('HTTP error')) {
          throw new Error('Server is not responding. Please try again later.');
        } else if (error.message.includes('NetworkError')) {
          throw new Error('Network connection error. Please check your internet connection.');
        } else {
          throw new Error(error.message || 'Failed to search videos. Please try again later.');
        }
      }
      
      // Wait before retrying - exponential backoff
      const waitTime = Math.min(1000 * Math.pow(2, retries - 1), 5000); // Max 5 seconds
      await delay(waitTime);
    }
  }
};

export const getVideoById = async (id) => {
  try {
    const response = await fetch(`${API_URL}/videos/${id}`, {
      headers: getHeaders()
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    
    if (data.status === 'error') {
      throw new Error(data.message || 'API returned error status');
    }
    
    return data.data;
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
};

// Categories
export const getTypes = async () => {
  try {
    const response = await fetch(`${API_URL}/types`, {
      headers: getHeaders()
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    return data.data;
  } catch (error) {
    console.error('Failed to fetch types:', error);
    throw error;
  }
};

export const getVideosByCategory = async (categoryId, page = 1, limit = 20, sort = 'time', filters = {}) => {
  // Process filters before sending
  const processedFilters = {
    page: page.toString(),
    limit: limit.toString(),
    sort
  };

  // Handle array filters (area and genre)
  if (filters.area && Array.isArray(filters.area) && filters.area.length > 0) {
    processedFilters.area = filters.area.join(',');
  }
  if (filters.genre && Array.isArray(filters.genre) && filters.genre.length > 0) {
    processedFilters.genre = filters.genre.join(',');
  }

  // Handle year range
  if (filters.year) {
    if (filters.year.start) {
      processedFilters.yearStart = filters.year.start;
    }
    if (filters.year.end) {
      processedFilters.yearEnd = filters.year.end;
    }
  }

  // Handle other filters
  Object.entries(filters).forEach(([key, value]) => {
    if (!['area', 'genre', 'year'].includes(key) && value !== undefined && value !== '') {
      processedFilters[key] = value;
    }
  });

  const params = new URLSearchParams(processedFilters);

  try {
    const response = await fetch(`${API_URL}/categories/${categoryId}/videos?${params}`, {
      headers: getHeaders()
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    
    return {
      videos: data.data.videos.map(video => ({
        ...video,
        vod_pic: video.vod_pic || '/default-poster.jpg'
      })),
      pagination: {
        current: page,
        limit,
        total: data.data.pagination.total
      }
    };
  } catch (error) {
    console.error('Failed to fetch videos by category:', error);
    throw new Error('Failed to load videos. Please try again later.');
  }
};

// Featured Content
export const getFeaturedContent = async () => {
  const response = await fetch(`${API_URL}/videos?page=1&limit=1&sort=hits`);
  const data = await response.json();
  if (data.status === 'error') throw new Error(data.message);
  const video = data.data.videos?.[0];
  if (!video) return null;
  return {
    id: video.vod_id,
    title: video.vod_name,
    description: video.vod_content,
    imageUrl: video.vod_pic,
    rating: video.vod_score,
    year: video.vod_year,
    duration: video.vod_duration,
    category: {
      id: video.type_id,
      name: video.type_name,
      en: video.type_en
    }
  };
};

// Content by Category
export const getTrendingVideos = async (limit = 20) => {
  try {
    const response = await fetch(`${API_URL}/videos?page=1&limit=${limit}&sort=hits`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    return data.data.videos;
  } catch (error) {
    console.error('Failed to fetch trending videos:', error);
    throw error;
  }
};

export const getPopularByCategory = async (categoryId, limit = 20) => {
  try {
    const response = await fetch(`${API_URL}/categories/${categoryId}/videos?page=1&limit=${limit}&sort=hits`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    return data.data.videos;
  } catch (error) {
    console.error('Failed to fetch popular videos:', error);
    throw error;
  }
};

// Video Provider
export const fetchFromProvider = async () => {
  try {
    const response = await fetch(`${API_URL}/videos/fetch`, {
      method: 'POST'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    return data;
  } catch (error) {
    console.error('Failed to fetch from provider:', error);
    throw error;
  }
};

// Add this new function to handle category fetching
export const getCategories = async () => {
  try {
    const types = await getTypes();
    
    // Transform the types into the correct category structure
    const categories = types.map(type => {
      let typeId = type.type_id;
      
      // Handle different ID formats
      if (typeof typeId === 'string') {
        if (typeId.includes('_')) {
          // Convert format like "1_1" to "11"
          const [main, sub] = typeId.split('_');
          typeId = parseInt(main + sub);
        } else {
          typeId = parseInt(typeId);
        }
      }
      
      return {
        ...type,
        type_id: typeId,
        // You can add additional category metadata here if needed
        is_main: typeId < 10,
        parent_id: typeId >= 10 ? Math.floor(typeId / 10) : null
      };
    });

    return categories;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

// Cache for trending movies
let trendingMoviesCache = {
  data: null,
  timestamp: null,
  expiryTime: 30 * 60 * 1000 // 30 minutes
};

// Fetch trending movies from TMDB with caching
export const getTrendingMovies = async () => {
  try {
    // Check if we have valid cached data
    if (
      trendingMoviesCache.data &&
      trendingMoviesCache.timestamp &&
      Date.now() - trendingMoviesCache.timestamp < trendingMoviesCache.expiryTime
    ) {
      return trendingMoviesCache.data;
    }

    // Fetch new data if cache is invalid
    const response = await fetch(`${API_URL}/trending`);
    if (!response.ok) throw new Error('Failed to fetch trending movies');
    
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);

    // Update cache
    trendingMoviesCache = {
      data: data.data,
      timestamp: Date.now(),
      expiryTime: 30 * 60 * 1000
    };

    return data.data;
  } catch (error) {
    console.error('Error fetching trending movies:', error);
    // Return cached data if available, even if expired
    return trendingMoviesCache.data || [];
  }
};

// Check if a movie exists in our database with caching
const movieAvailabilityCache = new Map();

export const checkMovieAvailability = async (tmdbId) => {
  try {
    // Check cache first
    if (movieAvailabilityCache.has(tmdbId)) {
      return movieAvailabilityCache.get(tmdbId);
    }

    const response = await fetch(`${API_URL}/movies/check-availability/${tmdbId}`);
    if (!response.ok) throw new Error('Failed to check movie availability');
    
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);

    // Cache the result
    movieAvailabilityCache.set(tmdbId, data.data);
    
    return data.data;
  } catch (error) {
    console.error('Error checking movie availability:', error);
    return null;
  }
};

// Get featured movies for hero section with caching
let featuredMoviesCache = {
  data: null,
  timestamp: null,
  expiryTime: 15 * 60 * 1000 // 15 minutes
};

export const getFeaturedMovies = async () => {
  try {
    // Check if we have valid cached data
    if (
      featuredMoviesCache.data &&
      featuredMoviesCache.timestamp &&
      Date.now() - featuredMoviesCache.timestamp < featuredMoviesCache.expiryTime
    ) {
      return featuredMoviesCache.data;
    }

    // Get trending movies from TMDB
    const trendingMovies = await getTrendingMovies();
    
    // Check each movie's availability in our database
    const availableMovies = await Promise.all(
      trendingMovies.map(async (movie) => {
        const movieData = await checkMovieAvailability(movie.tmdb_id);
        if (!movieData) return null;
        
        return {
          ...movieData,
          backdrop_path: movie.backdrop_path,
          tmdb_rating: movie.vote_average,
          tmdb_votes: movie.vote_count,
          overview: movie.overview
        };
      })
    );

    // Filter out unavailable movies and return the first 5
    const featured = availableMovies.filter(Boolean).slice(0, 5);

    // Update cache
    featuredMoviesCache = {
      data: featured,
      timestamp: Date.now(),
      expiryTime: 15 * 60 * 1000
    };

    return featured;
  } catch (error) {
    console.error('Error getting featured movies:', error);
    // Return cached data if available, even if expired
    return featuredMoviesCache.data || [];
  }
};

// Get movie recommendations
export const getMovieRecommendations = async (tmdbId) => {
  try {
    const response = await fetch(`${API_URL}/movies/${tmdbId}/recommendations`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    return data.data;
  } catch (error) {
    console.error('Failed to fetch recommendations:', error);
    return [];
  }
};

// Get similar movies
export const getSimilarMovies = async (tmdbId, vodClass) => {
  try {
    let endpoint;
    let params = new URLSearchParams();
    
    if (tmdbId) {
      endpoint = `${API_URL}/movies/${tmdbId}/similar`;
    } else if (vodClass) {
      endpoint = `${API_URL}/videos/recommendations`;
      // Take only the first class if vodClass contains multiple classes
      const firstClass = vodClass.split(',')[0];
      params.append('vod_class', firstClass);
      params.append('limit', '5');
    } else {
      throw new Error('Either tmdbId or vodClass must be provided');
    }
    
    const response = await fetch(`${endpoint}${params.size > 0 ? `?${params.toString()}` : ''}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    if (data.status === 'error') {
      throw new Error(data.message || 'Failed to fetch similar movies');
    }
    
    return data.data || [];
  } catch (error) {
    console.error('Failed to fetch similar movies:', error);
    throw error;
  }
};

// Search TMDB for a movie
export const searchTMDB = async (query, year = '') => {
  try {
    const response = await fetch(`${API_URL}/tmdb/search?${new URLSearchParams({
      query,
      year
    })}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    return data.data;
  } catch (error) {
    console.error('Failed to search TMDB:', error);
    return [];
  }
};

// Update videos with TMDB IDs
export const updateVideosWithTMDB = async () => {
  try {
    const response = await fetch(`${API_URL}/videos/update-tmdb`, {
      method: 'POST'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    return data.data;
  } catch (error) {
    console.error('Failed to update videos with TMDB IDs:', error);
    throw error;
  }
};

// Comments
export const getComments = async (videoId, page = 1, limit = 10, sortBy = 'time') => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      limit: limit.toString(),
      sort: sortBy // 'time' or 'likes'
    });

    const response = await fetch(`${API_URL}/videos/${videoId}/comments?${params}`, {
      headers: getHeaders()
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    
    return {
      comments: data.data.comments,
      total: data.data.total,
      currentPage: page,
      totalPages: Math.ceil(data.data.total / limit)
    };
  } catch (error) {
    console.error('Failed to fetch comments:', error);
    throw error;
  }
};

export const addComment = async (videoId, content, codeId, verificationCode, parentId = null, anonymousName, userId = null) => {
  const response = await fetch(`${API_URL}/videos/${videoId}/comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      content,
      comment_pid: parentId,
      codeId,
      verificationCode,
      anonymousName,
      user_id: userId
    })
  });

  const data = await response.json();
  if (data.status === 'error') throw new Error(data.message);
  return data.data;
};

// Update comment vote (up/down)
export const updateCommentVote = async (commentId, voteType) => {
  try {
    const response = await axios.post(`/api/comments/${commentId}/vote`, {
      type: voteType // 'up' or 'down'
    });
    return response.data;
  } catch (error) {
    console.error('Error updating vote:', error);
    throw error;
  }
};

// Guestbook
export const getGuestbookEntries = async (page = 1, limit = 20) => {
  const params = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString()
  });
  
  const response = await fetch(`${API_URL}/guestbook?${params}`);
  const data = await response.json();
  if (data.status === 'error') throw new Error(data.message);
  return data.data;
};

export const addGuestbookEntry = async (content, codeId, verificationCode, anonymousName) => {
  const response = await fetch(`${API_URL}/guestbook`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      content,
      codeId,
      verificationCode,
      anonymousName
    })
  });
  
  const data = await response.json();
  if (data.status === 'error') throw new Error(data.message);
  return data.data;
};

// Add new function to get genres
export const getGenresByCategory = async (categoryId, limit = 20) => {
  try {
    const response = await fetch(`${API_URL}/categories/${categoryId}/genres?limit=${limit}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    return data.data;
  } catch (error) {
    console.error('Failed to fetch genres:', error);
    throw error;
  }
};

// Add new function to get areas
export const getAreasByCategory = async (categoryId, limit = 10) => {
  try {
    const response = await fetch(`${API_URL}/categories/${categoryId}/areas?limit=${limit}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === 'error') throw new Error(data.message);
    return data.data;
  } catch (error) {
    console.error('Failed to fetch areas:', error);
    throw error;
  }
};

// Get danmuku for a video
export const getDanmuku = async (videoId, episode = 1) => {
    try {
        const response = await fetch(`${API_URL}/videos/${videoId}/danmuku?episode=${episode}`, {
            headers: getHeaders()
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        if (data.status === 'error') {
            throw new Error(data.message);
        }
        
        return data.data;
    } catch (error) {
        console.error('Failed to fetch danmuku:', error);
        return [];
    }
};

// Send new danmuku
export const sendDanmuku = async (videoId, danmuku) => {
    try {
        // Make sure episode is included in danmuku object
        const danmukuWithEpisode = {
            ...danmuku,
            episode: danmuku.episode || 1
        };

        const response = await fetch(`${API_URL}/videos/${videoId}/danmuku`, {
            method: 'POST',
            headers: {
                ...getHeaders(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(danmukuWithEpisode)
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        if (data.status === 'error') {
            throw new Error(data.message);
        }
        
        return data.data;
    } catch (error) {
        console.error('Failed to send danmuku:', error);
        throw error;
    }
};

// User Authentication APIs
export const registerUser = async (username, email, password, turnstileToken) => {
  const response = await fetch('/api/auth/register', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, email, password, turnstileToken })
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Registration failed');
  }
  return response.json();
};

export const loginUser = async (identifier, password, turnstileToken) => {
  const response = await fetch('/api/auth/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ identifier, password, turnstileToken })
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Login failed');
  }
  return response.json();
};

export const logoutUser = async () => {
  const response = await fetch('/api/auth/logout', {
    method: 'POST',
    credentials: 'include'
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Logout failed');
  }
  return response.json();
};

// User Favorites APIs
export const addToFavorites = async (vodId) => {
  const response = await fetch('/api/user/favorites', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ vodId })
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to add to favorites');
  }
  return response.json();
};

export const removeFromFavorites = async (vodId) => {
  const response = await fetch(`/api/user/favorites/${vodId}`, {
    method: 'DELETE'
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to remove from favorites');
  }
  return response.json();
};

export const getFavorites = async () => {
  const response = await fetch('/api/user/favorites');
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to get favorites');
  }
  return response.json();
};

// Watch Later APIs
export const addToWatchLater = async (vodId) => {
  const response = await fetch('/api/user/watchlist', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ vodId })
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to add to watch later');
  }
  return response.json();
};

export const removeFromWatchLater = async (vodId) => {
  const response = await fetch(`/api/user/watchlist/${vodId}`, {
    method: 'DELETE'
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to remove from watch later');
  }
  return response.json();
};

export const getWatchLater = async () => {
  const response = await fetch('/api/user/watchlist');
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to get watch later list');
  }
  return response.json();
};

// Watch History APIs
export const getUserHistory = async () => {
  const response = await fetch('/api/user/history');
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to get watch history');
  }
  return response.json();
};

export const addToHistory = async (vodId, episode, route, watchDuration, routeIndex) => {
  const response = await fetch('/api/user/history', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ vodId, episode, route, watchDuration, routeIndex })
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to add to history');
  }
  return response.json();
};

export const clearHistory = async () => {
  const response = await fetch('/api/user/history', {
    method: 'DELETE'
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to clear history');
  }
  return response.json();
};

export const deleteHistoryItem = async (vodId) => {
  const response = await fetch(`/api/user/history/${vodId}`, {
    method: 'DELETE'
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to delete history item');
  }
  return response.json();
};

// Process video URLs from v.qq.com, v.youku.com, and special identifiers (FanTv-*, XCGZ-*)
export const processVideoUrl = async (url, route) => {
  try {
    // Set a timeout for the fetch request
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 20000); // 20 second timeout
    
    // Use server-side API for all routes
    const apiUrl = `${API_URL}/process-video-url?url=${encodeURIComponent(url)}${route ? `&route=${encodeURIComponent(route)}` : ''}`;    
    const response = await fetch(apiUrl, {
      headers: getHeaders(),
      signal: controller.signal
    });
    
    clearTimeout(timeoutId); // Clear the timeout if the request completes
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    
    if (data.status === 'error') {
      // For client-side required case, handle properly
      if (data.errorCode === 'CLIENT_SIDE_REQUIRED') {
        throw new Error('客户端处理失败，请尝试其他线路');
      }
      
      // Include any error code from the server in the error object
      const error = new Error(data.message || '视频解析失败，请尝试其他线路');
      if (data.errorCode) {
        error.code = data.errorCode;
      }
      throw error;
    }
    
    return data.data;
  } catch (error) {
    // Handle specific error types
    if (error.name === 'AbortError') {
      console.error('Request timeout when processing video URL');
      throw new Error('视频解析服务响应超时，请尝试其他线路');
    }
    
    if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
      console.error('Network error when processing video URL:', error);
      throw new Error('视频解析服务暂时不可用，请尝试其他线路');
    }
    
    console.error('Failed to process video URL:', error);
    throw error;
  }
};

// App Version
export const getLatestAppVersion = async () => {
  try {
    const response = await fetch(`${API_URL}/app-versions/latest`, {
      headers: getHeaders()
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    if (data.status === 'error') {
      throw new Error(data.message || 'Failed to fetch app version data');
    }
    
    return data.data;
  } catch (error) {
    console.error('Failed to fetch app version data:', error);
    throw error;
  }
}; 