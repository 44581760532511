import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  alpha,
  Button,
  useTheme
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/Delete';
import Cookies from 'js-cookie';
import { useLanguage } from '../contexts/LanguageContext';
import { useAuth } from '../contexts/AuthContext';
import { getUserHistory, clearHistory, deleteHistoryItem } from '../services/api';

// Function to find the first 蓝光 route index
const findFirstBluRayRouteIndex = (route) => {
  if (!route) return 0;
  
  // Check if user has favorite routes
  const favoriteRoutesCookie = Cookies.get('favoriteRoutes');
  if (favoriteRoutesCookie) {
    try {
      const favoriteRoutes = JSON.parse(favoriteRoutesCookie);
      if (favoriteRoutes.includes(route)) {
        return 0; // Return 0 as we're just checking a single route here
      }
    } catch (error) {
      console.error('Error parsing favoriteRoutes cookie:', error);
    }
  }
  
  // For backward compatibility - check old single favorite route cookie
  const legacyFavoriteRoute = Cookies.get('favoriteRoute');
  if (legacyFavoriteRoute && legacyFavoriteRoute === route) {
    return 0; // Return 0 as we're just checking a single route here
  }
  
  const bluRayPriority = ['heimuer', 'ffm3u8', 'ikm3u8', '1080zyk'];
  const index = bluRayPriority.indexOf(route);
  return index !== -1 ? index : 0;
};

// Helper function to ensure valid route
const getValidRoute = (routeIndex, route) => {
  if (routeIndex !== undefined && routeIndex !== null) return routeIndex;
  if (route) return findFirstBluRayRouteIndex(route);
  return 0;
};

const HistoryWatched = ({ onHistoryItemClick }) => {
  const { t } = useLanguage();
  const { isAuthenticated } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [watchHistory, setWatchHistory] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const updateHistory = async () => {
    if (isAuthenticated) {
      try {
        const response = await getUserHistory();
        const dbHistory = response.data || [];
        setWatchHistory(dbHistory.map(item => {
          const validRoute = getValidRoute(item.route_index, item.route);
          const watchDuration = parseInt(item.watch_duration, 10);
          
          // Ensure watch_duration is a valid number
          const validWatchDuration = !isNaN(watchDuration) && watchDuration > 0 ? watchDuration : 0;
          
          return {
            vodId: item.vod_id,
            name: item.vod_name,
            url: `/watch/${item.vod_id}?route=${validRoute}&ep=${item.episode || 1}${validWatchDuration > 0 ? `&start_time=${validWatchDuration}` : ''}`,
            episode: item.episode,
            route: item.route,
            lastWatched: item.last_watched,
            watchDuration: validWatchDuration
          };
        }));
      } catch (error) {
        console.error('Error fetching DB history:', error);
        loadCookieHistory();
      }
    } else {
      loadCookieHistory();
    }
  };

  const loadCookieHistory = () => {
    const history = Cookies.get('watchHistory');
    if (history) {
      try {
        const parsedHistory = JSON.parse(history);
        const validHistory = Array.isArray(parsedHistory) ? parsedHistory : [];
        setWatchHistory(validHistory.map(item => {
          const validRoute = getValidRoute(item.routeIndex, item.route);
          const watchDuration = parseInt(item.watchDuration, 10);
          
          // Ensure watchDuration is a valid number
          const validWatchDuration = !isNaN(watchDuration) && watchDuration > 0 ? watchDuration : 0;
          
          return {
            ...item,
            url: `/watch/${item.vodId}?route=${validRoute}&ep=${item.episode || 1}${validWatchDuration > 0 ? `&start_time=${validWatchDuration}` : ''}`
          };
        }));
      } catch (error) {
        console.error('Error parsing watch history:', error);
        setWatchHistory([]);
      }
    } else {
      setWatchHistory([]);
    }
  };

  const clearAllHistory = async () => {
    if (isAuthenticated) {
      try {
        await clearHistory();
      } catch (error) {
        console.error('Error clearing DB history:', error);
      }
    }
    Cookies.remove('watchHistory');
    setWatchHistory([]);
    handleClose();
  };

  const handleDeleteHistoryItem = async (vodId, event) => {
    event.stopPropagation();
    if (isAuthenticated) {
      try {
        await deleteHistoryItem(vodId);
        setWatchHistory(prevHistory => prevHistory.filter(item => item.vodId !== vodId));
      } catch (error) {
        console.error('Error removing from DB history:', error);
      }
    }
    // Also remove from cookie for non-authenticated users or as backup
    const existingHistory = Cookies.get('watchHistory');
    if (existingHistory) {
      const history = JSON.parse(existingHistory);
      const newHistory = history.filter(item => item.vodId !== vodId);
      Cookies.set('watchHistory', JSON.stringify(newHistory), { expires: 30 });
      setWatchHistory(prevHistory => prevHistory.filter(item => item.vodId !== vodId));
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleVideoClick = (url) => {
    // If we're already on the watch page, just update the URL and reload the video
    if (location.pathname.startsWith('/watch/')) {
      const newUrl = new URL(url, window.location.origin);
      const currentUrl = new URL(window.location.href);
      
      // Only navigate if the URLs are different
      if (newUrl.toString() !== currentUrl.toString()) {
        navigate(url);
      }
    } else {
      // If we're on a different page, navigate normally
      navigate(url);
    }
    
    // Close the history menu
    handleClose();
    
    // Call the callback to close mobile overlay if provided
    if (onHistoryItemClick) {
      onHistoryItemClick();
    }
  };

  useEffect(() => {
    updateHistory();
    const intervalId = setInterval(updateHistory, 30000);
    return () => clearInterval(intervalId);
  }, [isAuthenticated]);

  return (
    <Box>
      <IconButton
        size="small"
        onClick={handleClick}
        aria-label={t('history', 'watchHistory')}
        title={t('history', 'watchHistory')}
        sx={{
          color: theme.palette.mode === 'light' ? 'text.primary' : 'text.navbar',
          '&:hover': {
            color: 'primary.main',
          },
        }}
      >
        <HistoryIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            mt: 1.5,
            width: 320,
            maxHeight: 400,
            zIndex: theme.zIndex.modal + 4,
          },
        }}
        sx={{ 
          zIndex: `${theme.zIndex.modal + 4} !important`
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          paper: {
            sx: {
              zIndex: theme.zIndex.modal + 4
            }
          }
        }}
      >
        <Box sx={{ px: 2, py: 1.5 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h6" component="div">
              {t('history', 'watchHistory')}
            </Typography>
            {watchHistory.length > 0 && (
              <Button
                size="small"
                color="error"
                onClick={clearAllHistory}
                startIcon={<DeleteIcon />}
              >
                {t('history', 'clearAll')}
              </Button>
            )}
          </Stack>
        </Box>

        {watchHistory.length === 0 ? (
          <MenuItem disabled>
            <Typography variant="body2" color="text.secondary">
              {t('history', 'noHistory')}
            </Typography>
          </MenuItem>
        ) : (
          watchHistory.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => handleVideoClick(item.url)}
              sx={{
                py: 1.5,
                px: 2,
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.08),
                },
              }}
            >
              <Stack spacing={0.5} sx={{ width: '100%', pr: 4, position: 'relative' }}>
                <Typography variant="body1" noWrap>
                  {item.name}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Typography variant="caption" color="text.secondary" noWrap>
                    {item.episode ? t('history', 'episodeNumber')(item.episode) : t('history', 'movie')}
                  </Typography>
                  {item.watchDuration > 0 && (
                    <Typography variant="caption" color="text.secondary" noWrap>
                      {new Date(item.watchDuration * 1000).toISOString().substr(11, 8)}
                    </Typography>
                  )}
                </Stack>
                <IconButton
                  size="small"
                  onClick={(e) => handleDeleteHistoryItem(item.vodId, e)}
                  sx={{
                    position: 'absolute',
                    right: -8,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'text.secondary',
                    opacity: 0.5,
                    '&:hover': {
                      opacity: 1,
                      color: 'error.main',
                    },
                  }}
                  title={t('history', 'deleteFromHistory')}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Stack>
            </MenuItem>
          ))
        )}
      </Menu>
    </Box>
  );
};

export default HistoryWatched; 